<template>
  <div class="collections-header">
    <div
      class="c-bg"
      :style="{
        backgroundImage: bg,
      }"
    ></div>
    <div class="c-avatar">
      <avatar :imgUrl="avatarUrl" rounded size="100%" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collections',
  props: {
    avatarUrl: String,
    bg: String,
  },
  data() {
    return {
      // data: {
      //   title: 'Official Kreepy Club',
      //   avatar_url:
      //     'https://lh3.googleusercontent.com/TelYObgXMwFw2nD1vzdh21_NA5uO43_dT6xdYCQjTJJsgbY1k5_oDWBInrlwWZp1znnrTV4xYNz3ik1wj74XDDKKvQUNIF8VN_f57g=s130',
      //   owners: '122',
      //   items: '3k',
      //   vol: '6.76',
      //   floor: '0.0188',
      // },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/index.scss';

.collections-header {
  margin-top: 40px;
  margin-bottom: 80px;
  position: relative;
  .c-bg {
    height: 260px;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
  }

  .c-avatar {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -60px;
    width: 180px;
    height: 180px;
    padding: 20px;
    background-color: #0b0e18;
    border-radius: 20px;
  }
}
</style>
