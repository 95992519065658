<template>
  <section class="container">
    <c-header :avatarUrl="data.avatar_url" :bg="data.bg" />
    <div class="text-white text-center pt-2">
      <h2 class="">{{ data.title }}</h2>
      <div class="actions">
        <chip-new size="lg">
          <div class="px-4">
            <div class="text-secondary text-10">{{ $t('collections.title.own') }}</div>
            <div class="text-white mt-2">{{ data.owners }}</div>
          </div>
          <div class="px-4">
            <div class="text-secondary text-10">{{ $t('collections.title.ite') }}</div>
            <div class="text-white mt-2">{{ numOfItems }}</div>
          </div>
        </chip-new>
        <!-- <btn class="btn-outline-secondary ml-4" circular>
          <heart-outline-svg stroke="white" />
        </btn> -->
      </div>
    </div>

    <div class="d-flex mt-5">
      <tabs v-model="tab" :options="types" size="md" class="mx-auto">
        <template v-slot:pending="{ item }">
          <span class="d-flex align-items-center">
            {{ item.label }}
            <b-badge v-if="numOfOffers" pill class="ml-2 px-2" variant="light">{{ numOfOffers }}</b-badge>
          </span>
        </template>
      </tabs>
    </div>
    <divider class="p-0" />

    <section v-if="tab.value === 'i'" class="container" style="margin-top:50px">
      <section-title>
        <div class="d-flex align-items-center">
          <drop-down class="ml-4" v-model="itemType" :data="itemTypes">
            <template v-slot:title="{ selected }">
              <all-svg />
              <span>{{ selected.label }}</span>
            </template>
            <template v-slot:all>
              <all-solid-svg class="mr-2" />
            </template>
          </drop-down>

          <drop-down-price-range class="ml-4" v-model="priceRange" />
        </div>
        <drop-down class="ml-auto" v-model="priceSort" :data="priceSortOptions">
          <template v-slot:title="{ selected }">
            <sort-down-svg v-if="priceSort.value === 'htl'" />
            <sort-up-svg v-else />
            {{ selected.label }}
          </template>
        </drop-down>
      </section-title>

      <b-pagination
        v-model="currentPage"
        :total-rows="filteredItems.length"
        :per-page="perPage"
        first-number
        last-number
      />

      <template v-if="!game">
        <div class="card-box mt-1">
          <nft-card-detailed class="card-item" v-for="(i, k) in items" :key="k + 'a'" :item="i" />
          <nft-card-detailed class="card-item" v-for="(i, k) in items" :key="k + 'b'" :item="i" />
          <nft-card-detailed class="card-item" v-for="(i, k) in items" :key="k + 'c'" :item="i" />
        </div>
      </template>

      <template v-else>
        <div
          v-if="filteredItems && filteredItems.length === 0"
          class="d-flex justify-content-center align-items-center text-secondary"
          style="height:300px"
        >
          没有找到相关装备~
        </div>
        <div class="card-box mt-1">
          <collection-weapon
            v-for="(item, index) in filteredItems"
            class="card-item"
            :key="index"
            :item="item"
            @click="$router.push(`/store/${item.market_hash_name}`)"
          />
        </div>
      </template>

      <!-- <div class="px-2 mt-3">
        <btn-color class="btn-outline-primary w-full">{{ $t('collections.ite.load') }}</btn-color>
      </div> -->
    </section>

    <section v-if="tab.value === 'a'" class="container" style="margin-top:50px">
      <div class="d-flex">
        <chip-select v-model="chip" :options="options">
          <template v-slot:listing="{ label }">
            <tag-svg />
            <span class="ml-2">{{ label }}</span>
          </template>
          <template v-slot:sale="{ label }">
            <bag-svg />
            <span class="ml-2">{{ label }}</span>
          </template>
          <template v-slot:offer="{ label }">
            <dollar-svg />
            <span class="ml-2">{{ label }}</span>
          </template>
          <template v-slot:transfer="{ label }">
            <truck-svg />
            <span class="ml-2">{{ label }}</span>
          </template>
        </chip-select>

        <slide-chip-select class="ml-auto" v-model="tableTimeRangeSelected" :options="tableTimeRange" />
      </div>

      <my-table class="mt-5" :data="filteredTableItems" :loading="tableLoading" :cols="cols">
        <template v-slot:type="{ item }">
          <div>
            <tag-svg v-if="item.type === 'listing'" />
            <bag-svg v-if="item.type === 'sale'" />
            <dollar-svg v-if="item.type === 'offer'" />
            <truck-svg v-if="item.type === 'transfer'" />
            <span class="ml-2"> {{ item.type }}</span>
          </div>
        </template>

        <template v-slot:item="{ item }">
          <div class="d-flex align-items-center">
            <avatar
              imgUrl="https://lh3.googleusercontent.com/xoh36xVPIigSxgV8DZG64KDeU1PHSQGHAI2EUMoDillue4GMV3aBhH7bsIri3izQkuxoUrwM8TGqxJkd6Z7DSMpJ1Qib0w3T46JfDA=s0"
            />
            <span class="ml-2"> {{ item.item }}</span>
          </div>
        </template>

        <template v-slot:price="{ item }">
          <div class="d-flex align-items-center">
            <eth-svg width="10" />
            <span class="ml-2"> {{ item.price }}</span>
          </div>
        </template>

        <template v-slot:from="{ item }">
          <div class="d-flex align-items-center">
            <span class=""> {{ item.from }}</span>
          </div>
        </template>

        <template v-slot:time="{ value }">
          <div class="d-flex align-items-center">
            <span class=""> {{ getTime(value) }}</span>
          </div>
        </template>
      </my-table>
    </section>
  </section>
</template>

<script>
import Header from './Header'
import { isValidDate } from '@/utils'
import { StoreService } from '@/services/api'

export default {
  name: 'Collections',
  components: { CHeader: Header },
  data() {
    return {
      tab: { label: this.$t('collections.ite.title'), value: 'i' },
      types: [
        { label: this.$t('collections.ite.title'), value: 'i' },
        // { label: this.$t('collections.act.title'), value: 'a' },
      ],
      game: '',
      loading: false,
      data: {},
      currentPage: 1,
      perPage: 50,
      chip: '',
      itemType: { label: this.$t('home.explore.menu1.all'), value: 'all' },
      itemTypes: [
        { label: this.$t('home.explore.menu1.all'), value: 'all' },
        { label: '消费级', value: '消费级' },
        { label: '军规级', value: '军规级' },
        { label: '工业级', value: '工业级' },
        { label: '匕首', value: '匕首' },
        { label: '受限', value: '受限' },
        { label: '保密', value: '保密' },
        { label: '隐秘', value: '隐秘' },
        { label: '普通级', value: '普通级' },
        { label: '高级', value: '高级' },
        { label: '非凡', value: '非凡' },
        { label: '奇异', value: '奇异' },
        { label: '卓越', value: '卓越' },
        { label: '违禁', value: '违禁' },
      ],
      priceRange: { type: 'usd', min: '', max: '' },
      priceSort: { label: this.$t('home.explore.menu4.htl'), value: 'htl' },
      options: [
        { label: 'Listing', value: 'listing' },
        { label: 'Sale', value: 'sale' },
        { label: 'Offer', value: 'offer' },
        { label: 'Transfer', value: 'transfer' },
      ],
      priceSortOptions: [
        { label: this.$t('home.explore.menu4.htl'), value: 'htl' },
        { label: this.$t('home.explore.menu4.lth'), value: 'lth' },
      ],
      tableTimeRangeSelected: { label: '15m', value: '15m' },
      tableTimeRange: [
        { label: this.$t('collections.act.select.15m'), value: '15m' },
        { label: this.$t('collections.act.select.1h'), value: '1h' },
        { label: this.$t('collections.act.select.4h'), value: '4h' },
        { label: this.$t('collections.act.select.1d'), value: '1D' },
        { label: this.$t('collections.act.select.7d'), value: '7D' },
      ],
      tableItems: [],
      tableLoading: false,
      cols: [
        { label: this.$t('collections.act.table.typ'), value: 'type' },
        { label: this.$t('collections.act.table.ite'), value: 'item', fixed: '200' },
        { label: this.$t('collections.act.table.pri'), value: 'price', fixed: '200' },
        { label: this.$t('collections.act.table.fro'), value: 'from', fixed: '200' },
        { label: this.$t('collections.act.table.to'), value: 'to', fixed: '150' },
        { label: this.$t('collections.act.table.tim'), value: 'time', fixed: '150' },
      ],
      items: [],
    }
  },
  created() {
    if (this.$route.params.id === 'dota2') {
      this.game = 'dota2'
      this.data = {
        title: 'DOTA2',
        avatar_url: require('@/assets/dota2-avatar-lg.png'),
        owners: '122',
        items: '3k',
        bg: 'url(' + require('@/assets/dota2-banner.png') + ')',
      }
      this.loadWeapons()
    }
    if (this.$route.params.id === 'csgo') {
      this.game = 'csgo'
      this.data = {
        title: 'CSGO',
        avatar_url: require('@/assets/csgo-avatar-lg.png'),
        owners: '122',
        items: '3k',
        bg: 'url(' + require('@/assets/csgo-banner.png') + ')',
      }
      this.loadWeapons()
    }
  },
  computed: {
    filteredItems() {
      let rv = this.items
      if (this.priceSort.value === 'htl') {
        rv = rv.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
      }
      if (this.priceSort.value === 'lth') {
        rv = rv.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
      }
      if (this.priceRange) {
        if (this.priceRange.min) {
          rv = rv.filter(i => parseFloat(i.price) >= this.priceRange.min)
        }
        if (this.priceRange.max) {
          rv = rv.filter(i => parseFloat(i.price) <= this.priceRange.max)
        }
      }
      if (this.itemType && this.itemType.value !== 'all') {
        rv = rv.filter(i => i.type.includes(this.itemType.value))
      }
      return rv
    },
    filteredTableItems() {
      let rv = this.tableItems
      if (this.chip) {
        rv = rv.filter(i => i.type === this.chip)
      }
      return rv
    },
    numOfItems() {
      if (!this.items) {
        return '-'
      }
      return this.items.length
    },
  },
  watch: {
    currentPage(v) {
      this.loadWeapons()
    },
  },
  methods: {
    getTime(v) {
      const dt = new Date(v)
      if (isValidDate(dt)) {
        return dt.toLocaleString('zh-CN')
      }
      return ''
    },
    async loadWeapons() {
      this.loading = true
      const { data } = await StoreService.getStore()
      this.items = data.data.map(i => {
        this.currentPage = data.current_page
        this.perPage = data.per_page
        const rv = i.item
        rv.price = i.price
        return rv
      })
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.actions {
  position: relative;
  display: flex;
  justify-content: center;
  /* padding-left: 100px; */
  margin-top: 30px;
}
</style>
